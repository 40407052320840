<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            Az üvegcipő és az avatár esete 
          </h2>
          <p class="hashText">- Az online és offline élet közti különbség</p>
        </b-col>
      </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="6">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                Könnyű elfelejteni, hogy az online világ és a közösségi média platformok valójában a füstjelek, levelek, távírógépek vagy akár a fotóalbumok unokái: fő céljuk a távkommunikáció, az információátadás. Széles körben való elterjedését a kényelemnek és a társalgás új irányzatainak köszönheti, miközben számos már meglévő alapmotívumot nagyított fel az emberi pszichében, mint például a tökéletességre való vágy vagy más emberek véleményének fontossága. Mindezt segítette az a tény is, hogy az online világ továbbra is limitációkkal operál: az jelenik meg rajta, amit mi feltöltünk vagy mások feltesznek rólunk, így igazából a valóságnak csupán egy kis szeletét láttatja. </p>

                <p class="text">
                Ahogy Hamupipőkét átvarázsolja Tündérkeresztanya, úgy mutathatunk mi is egészen más képet magunkról a közösségi médiában, melyhez varázspálca helyett elég pár digitális eszköz. Az alábbiakban összegyűjtöttük, hogy miben térhet el az üvegcipő a fapapucstól, azaz az online személyiségünk az offline-tól. </p>
            </div>
            </b-col>
            <b-col cols="12" lg="6" class="order-4 order-lg-1">
              <img src="images/hatos.jpg" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
            <b-col cols="12" lg="10" class="offset-lg-1 side-gap order-lg-2 order-1">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text"><b>Prezentáció:</b> Az online személyiségünk általában egy tökéletesített bemutatkozásunk, míg a valós életben lehetnek olyan hibák és gyengeségek, amelyeket nem mutatunk meg másoknak. Teljesen természetes, hogy ha éppen rossz passzban van valaki, azt nem szeretné világgá kürtölni: ez azonban egy egyenlőtlenül pozitív, csak a dolgok jó oldalát láttató profilt hoz létre. Ezáltal egy hamis világkép alakul ki, ami könnyen keltheti azt a látszatot, hogy mindenki másnak jobban megy a sora, mint nekünk, pedig ez valójában csak annyit jelent, amit mi is átélünk: hogy más sem szívesen vállalja fel hullámvölgyeit ország-világ előtt. </p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text"><b>Interakció:</b>Az online interakciók gyakran felületesek lehetnek, míg a valós életben sokkal szélesebb körű lehetőségek állnak rendelkezésre, hogy másokkal kapcsolatba lépjünk. Gondoljunk csak egy tetszik gombra és annak való életben megfeleltethető kommunikációjára: a dicséretre, a tetszés kifejezésére. Ha csak az emojik olykor titokzatos, mindenkinek mást jelentő használatára gondolunk, szintén nagy eltérést tapasztalhatunk. </p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>Időkezelés:</b> Az online személyiségünkön keresztül lehetőségünk van arra, hogy időben előzetesen megtervezzük, mit mondunk, és hogyan mutatjuk be magunkat, míg a valós életben azonnal reagálnunk kell a körülöttünk lévő helyzetekre és emberekre. Ismerős az az érzés, amikor a zuhany alatt jutnak az eszünkbe a frappáns visszavágások egy múltbéli vitára? Nos, az internet igazi partner ebben is. </p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">4</span>
                  <p class="text"><b>Környezet:</b> Az online személyiségünk kontrollált környezetben működik, míg a valós életben bármilyen helyzetben előfordulhatnak váratlan események, akárcsak az előző pontnál. Ugyan számos kísérlet van rá, az online világ egyelőre még nem modellezte le a valós világ váratlan kihívásait. </p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">5</span>
                  <p class="text"><b>Szerepek:</b> Az online személyiségünkön keresztül szerepeket játszhatunk és identitásunkat átalakíthatjuk, míg a valós életben az identitásunk és szerepeink állandóak, és nehezebb megváltoztatni őket. Elég egy új profil, pár új ismerős, egy álnév vagy egy megszerkesztett kép, és teljesen új személyazonossággal vethetjük bele magunkat az online életünkbe. </p>
                </li>
              </ul>
            </p>
            <p class="text">Összességében az online személyiségünk és a valós életünk közötti különbségek azt jelentik, hogy az online világban való viselkedésünk és reakcióink nem mindig tükrözik teljes mértékben a valós életünket. Nincs azzal semmi baj, ha olykor-olykor kedvünk szottyan az üvegcipőre, viszont a magunkkal való őszintéség és érzéseink validálása, de elsősorban az önelfogadás az, ami számít a fapapucsos napokon.</p>
        </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Az üvegcipő és az avatár esete", 
        desc: "Könnyű elfelejteni, hogy az online világ és a közösségi média platformok valójában a füstjelek, levelek, távírógépek vagy akár a fotóalbumok unokái: fő céljuk a távkommunikáció, az információátadás.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Az üvegcipő és az avatár esete",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Könnyű elfelejteni, hogy az online világ és a közösségi média platformok valójában a füstjelek, levelek, távírógépek vagy akár a fotóalbumok unokái: fő céljuk a távkommunikáció, az információátadás. "        
    })
  }
}
</script>